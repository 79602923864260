import classNames from 'classnames';
import { FC, isValidElement } from 'react';
import { Section, SectionProps } from 'ui/sections/section';
import styles from './TrustedCompaniesSection.module.scss';

const defaultData = {
  headline: 'Join hundreds of companies that trust Turing to hire remote software developers',
  desktopLogos: [
    { src: '/img/trust-companies/Carta.svg', alt: 'Carta Healthcare' },
    { src: '/img/trust-companies/Hotstar.svg', alt: 'Disney Hotstar' },
    { src: '/img/trust-companies/CapitalRX 2.svg', alt: 'Capital RX' },
    { src: '/img/trust-companies/glassdoor.svg', alt: 'Glassdoor' },
    { src: '/img/trust-companies/Dell.svg', alt: 'Dell' },
    { src: '/img/trust-companies/Pepsi.svg', alt: 'Pepsi' },
    { src: '/img/trust-companies/VillageMD 1.svg', alt: 'Village MD' },
    { src: '/img/trust-companies/Rivian 1.svg', alt: 'Rivian' },
    { src: '/img/trust-companies/Plume.svg', alt: 'Plume' },
  ],
  mobileLogos: null,
  titleColor: '',
};

const renderLogos = logos => {
  return logos.map((company, index) => {
    if (isValidElement(company)) {
      return company;
    } else if (typeof company === 'string') {
      // if we're getting the logo's as an SVG string, directly insert that into the DOM
      return <span key={index} dangerouslySetInnerHTML={{ __html: company }} />;
    } else {
      return (
        <img
          src={company.src}
          alt={company.alt}
          loading="lazy"
          width={company.width || 72}
          height={company.height || 72}
          key={index}
        />
      ); // else use and Img tag
    }
  });
};

export type TrustedCompaniesLogo =
  | string
  | JSX.Element
  | {
      src: string;
      alt: string;
      width?: number;
      height?: number;
    };

export interface TrustedCompaniesLogos {
  headline: string;
  desktopLogos: TrustedCompaniesLogo[];
  mobileLogos?: TrustedCompaniesLogo[];
  titleColor?: string;
}

export interface TrustedCompaniesSectionProps {
  sectionProps?: SectionProps;
  withoutSection?: boolean;
  data: TrustedCompaniesLogos;
  marginTop?: boolean;
}

const TrustedCompaniesSection: FC<TrustedCompaniesSectionProps> = ({
  sectionProps = {},
  withoutSection = false,
  data: { headline, desktopLogos, mobileLogos, titleColor = '' } = defaultData,
  marginTop = false,
}) => {
  const defaultSectionProps = {
    topPad: 'none',
    ...sectionProps,
  };

  const renderContent = () => {
    return (
      <>
        <h2
          className={classNames(styles.root__title, {
            [styles.black]: titleColor === 'black',
            [styles.marginTop]: marginTop,
          })}
        >
          {headline}
        </h2>
        {desktopLogos && (
          <div
            className={classNames(styles.root__logosContainer, {
              [styles.withoutSection]: withoutSection,
              'mobile-hidden': !!mobileLogos,
            })}
          >
            {renderLogos(desktopLogos)}
          </div>
        )}

        {mobileLogos && (
          <div
            className={classNames(
              styles.root__logosContainer,
              { [styles.withoutSection]: withoutSection },
              'desktop-hidden',
              'tablet-hidden',
            )}
          >
            {renderLogos(mobileLogos)}
          </div>
        )}
      </>
    );
  };

  if (withoutSection) {
    return renderContent();
  }

  return (
    <Section {...defaultSectionProps} className={styles.root}>
      {renderContent()}
    </Section>
  );
};

export default TrustedCompaniesSection;
