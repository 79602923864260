import { constants } from 'ui/utils/constants';
import { getCtaURL } from '../session-helper';

export const handleEmailSubmit = (email: string, formType: string, serviceName?: string) => {
  const ctaUrl = getCtaURL(
    formType === 'Staff Augmentation'
      ? constants.CUSTOMER_FUNNEL_URL
      : formType === 'Services'
      ? constants.CUSTOMER_SERVICES_FUNNEL_URL
      : undefined,
  );
  const href = new URL(ctaUrl);
  href.searchParams.set('email', email);
  if (serviceName) {
    href.searchParams.set('sub', serviceName);
  }

  window.location.href = href.toString();
};
