import { ReactNode } from 'react';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import { Locale } from 'ui/config/locales';
import { useClientDefaultLocale } from 'ui/hooks/useClientDefaultLocale';
import { PrismicNextLink } from '@prismicio/next';
import { LinkField, LinkResolverFunction } from '@prismicio/client';
import cn from 'classnames';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import styles from './Link.module.scss';

type LinkProps = Omit<NextLinkProps, 'href'> & {
  locale?: Locale;
  dataTestId?: string;
  className?: string;
  children: ReactNode;

  /**
   * Figma component type. 
   * Link: https://www.figma.com/design/LscgrnSWSr6UiU7NcQVSRQ/Turing-Design-System-v2?node-id=3350-17744&t=pKmX7QcJl695Yo8Z-0
   * 
   * @default 'body'
   */
  type?: 'body' | 'subheading' | 'emphasis';

  /**
   * Icon to be displayed after the link text.
   * 
   * @default ArrowForward
   */
  endIcon?: ReactNode;

  /**
   * Remove all styling from the link. Useful for wrapping complex ui components.
   * 
   * @default false
   */
  unstyled?: boolean;

  /**
   * Link to a page on the website.
   */
  href?: string;

  /**
   * Prismic LinkField.
   */
  field?: LinkField;

  /**
   * Prismic custom link resolver function.
   */
  linkResolver?: LinkResolverFunction;
}

/**
 * Reusable Link component. Supports Prismic links as well as regular links.
 * 
 * Figma: {@Link https://www.figma.com/design/LscgrnSWSr6UiU7NcQVSRQ/Turing-Design-System-v2?node-id=3350-17744&t=pKmX7QcJl695Yo8Z-0}
 * 
 * Tip: Wrapping a `Link` in another `Link` with `unstyled={true}`, will trigger the hover 
 * animation on the wrapped `Link` when hovering on the parent `Link`.
 * Do not pass `href` or `field` on the wrapped `Link` to avoid react hydration error.
 *
 * ```tsx
 * <Link unstyled href="/path/to/somewhere">
 *   <span>Some other content</span>
 *   <Link type="emphasis">
 *     Read more
 *   </Link>
 * </Link>
 * ```
 */

export default function Link({
  href,
  locale,
  children,
  dataTestId,
  field,
  className,
  type,
  endIcon = <ArrowForward />,
  unstyled = false,
  ...props
}: LinkProps) {
  const controleHref = href || '/';
  const clientDefaultLocale = useClientDefaultLocale(href);

  let classNames = cn(styles.root, styles[`type-${type}`], className);

  if (unstyled) {
    classNames = cn(styles.unstyled, className);
  }

  // Prismic Link
  if (field) {
    return (
      <PrismicNextLink
      locale={locale || clientDefaultLocale}
      passHref
      {...props}
      data-testid={dataTestId}
      className={classNames}
      field={field}
    >
      {children}
      {type === 'emphasis' && endIcon}
    </PrismicNextLink>
    )
  }
  
  // Next Link
  if (href) {
    return (
      <NextLink
        locale={locale || clientDefaultLocale}
        passHref
        {...props}
        data-testid={dataTestId}
        className={classNames}
        href={controleHref}
      >
        {children}
        {type === 'emphasis' && endIcon}
      </NextLink>
    )
  }

  return (
    <span
      {...props}
      data-testid={dataTestId}
      className={classNames}
    >
      {children}
      {type === 'emphasis' && endIcon}
    </span>
  );
};
