export const normalizeBlogs = (relatedBlogs, items) => {
  const normalizeBlogItem = blogItem => {
    if (!blogItem || !blogItem.data) return null;

    const transformedCategories = (blogItem.data.categories || []).map(categoryItem => {
      return {
        tag: categoryItem?.category?.data?.sub_heading || {},
      };
    });

    return {
      id: blogItem.id,
      title: blogItem.data.title,
      description: blogItem.data?.description || '',
      imageUrl: blogItem.data.image?.url || '',
      url: blogItem.data?.url?.url || blogItem.url || '',
      tags: blogItem.data.tags || transformedCategories,
      ctaText: blogItem.data.cta || 'Read more',
      category: blogItem.data.category || '',
    };
  };

  const normalizedRelatedBlogs = (relatedBlogs || []).map(blog => normalizeBlogItem(blog.blog));

  const normalizedItems = (items || []).map(item => normalizeBlogItem(item.related_blogs));

  return normalizedRelatedBlogs.length > 0 ? normalizedRelatedBlogs : normalizedItems;
};
