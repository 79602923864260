import { constants } from "ui/utils/constants";
import { HttpService } from "./http.service";

export const mxServerFunction = async (email: string) => {
  try {
    const { data } = await HttpService.get(
      `${constants.CF_GET_EMAIL_VALIDATION_API}?email=${email}`,
    );
    return data.isValid || 'Please enter a valid work email';
  } catch (err) {
    return false || 'Please enter a valid work email';
  }
  };