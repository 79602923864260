import axios from 'axios';
import { constants } from 'ui/utils/constants';

export const HttpService = axios.create({});

HttpService.interceptors.request.use(config => {
  // eslint-disable-next-line no-console
  console.info(`Sending request to: ${config.url}`);
  // Set matching api token
  if (config.headers.matchingToken) {
    config.headers['service-account-token'] =
      constants.MATCHING_SERVICE_ACCOUNT_TOKEN;
    delete config.headers.matchingToken;
    config.baseURL = constants.MATCHING_BASE_URL;
  }
  return config;
});
